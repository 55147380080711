@import url(https://fonts.googleapis.com/css2?family=Barlow:wght@100&family=Oswald:wght@200&family=Train+One&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
    height: 100%;
    font-family: 'Roboto', sans-serif !important;
    color: #D8CECE !important;
    font-size: 15px;
    background: url(/static/media/background-1.7e7741be.png);
    background-repeat: no-repeat;
    background-size: cover;
}
html {
    /* background: url('./img/background-1.png');
    background-repeat: no-repeat;
    background-size: cover; */
    background-color: #211a1c;
}

a, h1, h2, h3, h4, h5, h6 {
    color: #BE2D2D !important;
}

.page {
    height: 100%;
}

.header-general {
    font-size: 80px !important;
    text-transform: uppercase !important;
}

.header-name {
    color: #D8CECE !important;
    font-family: 'Oswald', sans-serif!important;
    font-weight: 50 !important;
}

.header-job {
    color: #BE2D2D !important;
    font-family: 'Train One', cursive !important;
    text-shadow: 0 0 13px #B94242;
}

/* GÉNÉRAL */
@media screen and (max-width: 570px) {
    .header-general {
        font-size: 50px !important;
    }
}
@media screen and (max-width: 400px) {
    .header-general {
        font-size: 40px !important;
    }
}
@media screen and (max-width: 365px) {
    .header-general {
        font-size: 30px !important;
    }
}

/* ----- HOME ----- */
.see-more {
    font-size: 30px;
    transition: 0.9s;
    font-family: 'Train One', cursive !important;

}
.see-more:hover {
    text-shadow: 0 0 13px #B94242;
}

.home-page {
    height: 100%;
}

.home {
    height: 1000px;
    padding-top: 300px;
}


/* .home .web-dev {
    animation: glow 1s ease-in-out infinite alternate;
} */
.home .web-dev {
    font-size: 80px;
    color: #fff;
    text-align: center;
    -webkit-animation: glow 1s ease-in-out infinite alternate !important;
    animation: glowLetter 2s ease infinite alternate !important;
    text-shadow: 0 0 transparent;
  }
  
  @-webkit-keyframes glowLetter {
    0% { 
        text-shadow: 0 0 10px #B94242;
    }
    30% { 
        text-shadow: 0 0 10px transparent;
    }
    68%, 72% { 
        text-shadow: 0 0 10px #B94242;
    }
    100% {text-shadow: 0 0 10px #B94242;
    }
  }
  
  @keyframes glowLetter {
    0% { 
        text-shadow: 0 0 10px #B94242;
    }
    30% { 
        text-shadow: 0 0 10px transparent;
    }
    68%, 72% { 
        text-shadow: 0 0 10px #B94242;
    }
    100% {text-shadow: 0 0 10px #B94242;
    }
  }

.carousel-indicators li {
    border-top: 0 !important;
    border-bottom: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    background-color: #b94242 !important;
}
.carousel-indicators .active {
    box-shadow: 0 0 13px #B94242;
}

.more {
    position: absolute;
    margin: auto;
    bottom: 0 !important;
    width: 100%;
    /* transform: translate(-50%); */
}
.more .downbtn {
    text-align: center;
    text-shadow: transparent !important;
    font-size: 50px;
    color: #D8CECE !important;
}

.home-work {
    padding-top: 100px;
    margin-bottom: 200px;
    height: 100%;
}

.home-creations {
    height: 100%;
    padding-top: 100px;
    margin-bottom: 200px;
}

.home-whoami {
    height: 100%;
    padding-top: 100px;
    margin-bottom: 200px;
}

/* IPAD PRO */
@media screen and (max-width: 1026px) {
    .home-work {
        padding-top: 400px;
        margin-bottom: 200px;
        height: 100%;
    }
    
    .home-creations {
        height: 100%;
        padding-top: 400px;
        margin-bottom: 200px;
    }
    
    .home-whoami {
        height: 100%;
        padding-top: 400px;
        margin-bottom: 200px;
    }
}

/* IPAD PRO */
@media screen and (max-width: 1025px) and (min-height: 1400px) {
    .home {
        height: 1400px;
        padding-top: 600px !important;
    }
    .home-work {
        padding-top: 400px;
        margin-bottom: 200px;
        height: 100%;
    }
    
    .home-creations {
        height: 100%;
        padding-top: 400px;
        margin-bottom: 200px;
    }
    
    .home-whoami {
        height: 100%;
        padding-top: 400px;
        margin-bottom: 200px;
    }
}

/* ----- WORK ----- */
.work-body {
    height: 100%;
    margin-bottom: 90px;
}


/* ----- CREATIONS ----- */

/* ----- ABOUT ----- */
.about-page .header-name {
    font-size: 50px !important;
}

.about-page {
    font-family: 'Oswald', sans-serif!important;
}

.about-page .carousel-inner {
    height: 800px;
}
.about-page a{
    text-transform: uppercase;
    font-weight: 200;
    transition: 1s;
}

.about-page a:hover{
    text-shadow: 0 0 13px #B94242;
}

.about-page .about-content-1 {
    font-size: 60px;
}

.about-page .about-content-2 {
    font-size: 40px;
}

.about-page .social {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    margin: auto !important;
}

.about-page .social a{
    font-size: 30px;
}

.about-page .about-carousel-item {
    height: 100%;
    padding-top: 17%;
}


/* IPAD PRO */
@media screen and (max-width: 1025px) and (max-height: 1400px) {
    .about-carousel-item .header-name {
        font-size: 30px !important;
    }

    .about-carousel-item .ui.center.aligned.container {
        /* margin-left: aut !important;
        margin-right: 100px !important; */
        width: -webkit-fit-content !important;
        width: -moz-fit-content !important;
        width: fit-content !important;
    }

    .about-carousel-item .header-general {
        font-size: 20px !important;

    }
    .about-carousel-item .about-content-1 {
        font-size: 40px;
    }
    
    .about-carousel-item .about-content-2 {
        font-size: 27px;
    }
    .about-page .about-carousel-item {
        padding-top: 30% !important;
    }

    .about-carousel-item .carousel-inner {
        height: 1200px;
    }
}
/* IPAD */
@media screen and (max-width: 769px) and (max-height: 1025px) {
    .about-page .about-carousel .header-name {
        font-size: 30px !important;
    }

    .about-page .about-carousel .ui.center.aligned.container {
        width: -webkit-fit-content !important;
        width: -moz-fit-content !important;
        width: fit-content !important;
    }

    .about-page .about-carousel .header-general {
        font-size: 20px !important;
    }

    .about-page .about-carousel .header-job {
        font-size: 35px !important;
    }

    .about-page .about-carousel .about-content-1 {
        font-size: 30px;
    }
    
    .about-page .about-carousel .about-content-2 {
        font-size: 20px;
    }
    .about-page .about-carousel-item {
        padding-top: 45% !important;
    }
    .about-page .about-carousel .carousel-inner {
        height: 850px;
    }
}

/* IPHONE X */
@media screen and (max-width: 376px) and (max-height: 813px) {
    .about-page .about-carousel .header-name {
        font-size: 25px !important;
    }

    .about-page .about-carousel .ui.center.aligned.container {
        width: -webkit-fit-content !important;
        width: -moz-fit-content !important;
        width: fit-content !important;
    }

    .about-page .about-carousel .header-general {
        font-size: 15px !important;
    }

    .about-page .about-carousel .header-name {
        font-size: 25px !important;
    }

    .about-page .about-carousel .header-job {
        font-size: 20px !important;
    }

    .about-page .about-carousel .about-content-1 {
        font-size: 20px;
    }
    
    .about-page .about-carousel .about-content-2 {
        font-size: 17px;
    }
    .about-page .about-carousel {
        height: 50% !important;
        padding-top: 17% !important;
    }
    .about-page .about-carousel .ui.stackable.grid {
        margin: auto !important;
    }
    .about-page .about-carousel .carousel-inner {
        height: 500px;
    }
}

/* ----- CONTACT ----- */
.contact-page {
    padding-top: 50px;
}
.contact-page .contact-body {
    margin-top: 50px;
}
.contact-body {
    height: 100%;
    margin-bottom: 90px;
    font-family: 'Oswald', sans-serif!important;
}

.contact-body label {
    font-size: 20px !important;
    color: #D8CECE !important;
}

.contact-body a {
    padding: 10px 20px;
    color: #BE2D2D !important;
    font-family: 'Train One', cursive !important;
    border: 1px solid #BE2D2D !important;
    transition: 1s ease !important;
}

.contact-body a:hover {
    color: #BE2D2D !important;
    background-color: transparent !important;
    border: 1px solid #BE2D2D !important;
    box-shadow: 0 0 13px #B94242;
}

.contact-body .ui.button{
    background-color: transparent !important;
    padding: 0 !important;
    margin: 0 !important;
}

.contact-body .ui.loading.loading.loading.loading.loading.loading.button a {
    background-color: transparent !important;
    color: transparent !important;
}
.contact-body .ui.loading.loading.loading.loading.loading.loading.button a:hover {
    box-shadow: 0;
}

/* ----- MENU ----- */
.hamburger-svg {
    opacity: 0.7;
    transition: opacity 1s;
}
.hamburger-svg:hover {
    opacity: 1;
}

.navbar-menu {
    top: 0;
    width: 100%;
    position: fixed;
    padding: 20px;
}

.navbar-menu .logo-site{
    font-size: 30px;
}

.overlay {
    animation: fadeIn ease 0.5s;
    -webkit-animation: fadeIn ease 0.5s;
    -moz-animation: fadeIn ease 0.5s;
    -o-animation: fadeIn ease 0.5s;
    -ms-animation: fadeIn ease 0.5s;
    text-transform: uppercase;
    height: 100%;
    width: 100%;
    display: none;
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    background-color: rgb(41, 35, 35);
    background-color: rgba(41, 35, 35, 0.9);
}
  
.overlay-content {
    position: relative;
    top: 25%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
    font-size: 50px;
    font-weight: 100px;
    font-family: 'Train One', cursive !important;
}
.overlay-content a {
    margin-bottom: 50px;
}
  
.overlay a {
    opacity: 0.8;
    padding: 8px;
    text-decoration: none;
    color: #BE2D2D;
    display: block;
    transition: 1s;
}

.overlay a:hover, .overlay a:focus {
    opacity: 1;
    color: #BE2D2D;
    text-shadow: 0 0 13px #B94242;
}

.overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 60px;
}

@media screen and (max-height: 450px) {
    .overlay a {font-size: 20px}
    .overlay .closebtn {
        font-size: 40px;
        top: 20px;
        right: 20px;
    }
}

/* ----- LOADING PAGE ----- */
.loading-overlay {
    transition: height 1s;
    text-transform: uppercase;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    background-color: #211a1c;
}

.loading-overlay .loading-content {
    position: relative;
    top: 25%;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    font-size: 50px;
    font-weight: 100px;
    font-family: 'Train One', cursive !important;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

/* ----- FOOTER ----- */
.footer {
    position: relative;
    bottom: 10px;
    width: 100%;
}
.footer a {
    font-size: large !important;
    color: #BE2D2D !important;
    transition: 1s;
}

.footer .ui.list .list>a.item i.icon, .ui.list>a.item i.icon {
    color: #BE2D2D !important;
    transition: 1s !important;
}

.footer .ui.horizontal.bulleted.list>.item::before, ul.ui.horizontal.bulleted.list li::before{
    color: #BE2D2D !important;
}

.footer a:hover {
    text-shadow: 0 0 13px #B94242;
}
